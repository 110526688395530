@import url(bootstrap.min.css);
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Barlow", sans-serif;
    color: 2b400f;
}

.main-bg {
    background-color: #f9fdf3;
}

.btn.btn-outline-success, .btn.btn-outline-success:hover {
    background-color: transparent;
    border: none;
    color: #2B400F;
}

.nav-pills ul {
    overflow: auto;
}
  
.navbar-dark {
    background-color: transparent;
}

.navbar-dark .navbar-nav .nav-link {
    color: #2B400F;
}

#nav-dashboard .nav-item:first-child {
    border-right: solid 1px rgba(43,64,14,.3);
}

.nav-pills {
    margin-top: 95px !important;
}

.nav-pills .nav-link {
    background-color: #b0b5a9 !important;
    color: #2b400f;
    border-radius: 50px !important;
    margin-right: 15px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .nav-pills .nav-link:hover {
    background-color: #2b400f !important;
    color: #ffffff;
    border-radius: 50px;
}

.btn-info#button-download-as-xls {
    background-color: #2b400f !important;
    border: solid 1px #2b400f;
    color: #ffffff;
    border-radius: 50px;
}
.summary-value {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}