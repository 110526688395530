@import url(bootstrap.min.css);
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Barlow", sans-serif;
}


/*fonts*/


h1 {
    font-size: 4rem;
    font-weight: 800;
}

h2 {
    font-size: 3.313rem;
    font-weight: 700;
    color: #2b400f;
}

h3 {
    font-size: 2rem;
    font-weight: 500;
    color: #2b400f;
    line-height: 1.5;
}

p {
    color: #2b400f;
}

p.subtitle {
    font-size: 1.375rem;
    font-weight: 500;
    color: #2b400f;
}

p.text-bigger {
    font-size: 1.375rem;
}

.bg-lightgreen {
    background-color: #F2F3E9;
}

.bg-darkgreen {
    background-color: #2b400f;
}

.text-darkgreen {
    color: #2b400f;
}

a.text-link:hover, a.text-link:focus, a.text-link:active {
    color: #2b400f !important;
}

.text-link {
    color: #2b400f;
}

/*Navbar*/
.navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
}

.fixed-top.scrolled {
    background-color: #ffffff;
    box-shadow: -1px 4px 5px 0px rgba(207,207,207,0.5);
-webkit-box-shadow: -1px 4px 5px 0px rgba(207,207,207,0.5);
-moz-box-shadow: -1px 4px 5px 0px rgba(207,207,207,0.5);
}

.fixed-top.scrolled.navbar-dark .navbar-nav .nav-link {
    color: #2b400f;
}

.navbar-dark .btn.btn-outline-primary {
    border: solid 1px #ffffff;
    color: #ffffff;
    background-color: transparent;
    border-radius: 0;
}

.navbar-dark .btn.btn-outline-primary-dark {
    border: solid 1px #2b400f;
    color: #2b400f;
    background-color: transparent;
    border-radius: 0;
}

.navbar-dark .btn.btn-outline-primary:hover, .btn.btn-outline-primary:active, .btn.btn-outline-primary:focus {
    border: solid 1px #ffffff;
    color: #2b400f;
    background-color: #ffffff;
    border-radius: 0;
}

.fixed-top.scrolled.navbar-dark .btn.btn-outline-primary {
    border: solid 1px #2b400f;
    color: #2b400f;
    background-color: transparent;
    border-radius: 0;
}

.fixed-top.scrolled.navbar-dark .btn.btn-outline-primary:hover, .btn.btn-outline-primary:active, .btn.btn-outline-primary:focus {
    border: solid 1px #2b400f;
    color: #ffffff;
    background-color: #2b400f;
    border-radius: 0;
}



/*Banner*/

.main-banner {
    background-image: url(../img/banner@2x.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #ffffff;
    height: auto;
    min-height: 100vh;
}

.login-banner {
    background-image: url(../img/login-banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #ffffff;
    height: auto;
    min-height: 100vh;
}


p.lead {
    font-size: 1.875rem;
    font-weight: 500;
}


.main-banner #arrow {
    animation: float 3s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

/*problema*/

.card {
    box-shadow: -1px 0px 15px -2px rgba(124, 139, 41, 0.2);
    -webkit-box-shadow: -1px 0px 15px -2px rgba(124, 139, 41, 0.2);
    -moz-box-shadow: -1px 0px 15px -2px rgba(124, 139, 41, 0.2);
    border: none;
    border-radius: 0;
}

.cell-summary {
    background-color: #f7f7e9;
}

.subcell-summary {
    background-color: #ebebda;
}

.card-header {
    border: none;
    background-color: transparent;
    padding-top: 1.5rem;
}

/*solucion*/

.solucion-section {
    background-image: url(../img/solution@2x.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

}

.solucion-section .bg-darkgreen {
    background-color: #2b400f;
    height: 700px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

}

.bg-cows {
    background-image: url(../img/cows.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 600px;
}


/*calculadora*/

.calculadora {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(242, 243, 233, 1) 50%, rgba(242, 243, 233, 1) 100%);
}


.form-control, .input-group-text {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #2b400f;
}

.form-control:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: solid 2px #2b400f; 
}

.page-link:focus {
    box-shadow: none;
}

.roundedInput {
    padding: 6px 8px;
    border-radius: 4px;
    border: solid 1px #ced4da;
}

.roundedInput:focus {
    border: solid 1px #ced4da !important;
}

::placeholder {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    color: #73776D;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn.btn-primary {
    border: solid 1px #2b400f;
    background-color: #2b400f;
    border-radius: 0;
}

footer {
    background-color: #272727;
}

@media all and (max-width: 991px) {
    .solucion-section {
        background-image: none;
    }

    .solucion-section .bg-darkgreen {
        height: auto;
    }
}


@media all and (max-width: 767px) {
    .main-banner {
        background-image: url(../img/banner-xs.png);
    }

    h1 {
        font-size: 3.5rem;
    }

    p.lead, h3 {
        font-size: 1.5rem;
    }

    p.subtitle {
        font-size: 1.4rem; 
    }

   
}